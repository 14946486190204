import React from 'react';
import MoneyAmount from '../MoneyAmount';
import './index.scss';

export interface EstimateSummaryProps {
  image?: string;
  title: string;
  startingFromPrice?: number;
  description: string;
}

function EstimateSummary({
  image,
  title,
  startingFromPrice,
  description,
}: EstimateSummaryProps) {
  return (
    <div className="estimate-summary-container">
      {image ? <img className="estimate-summary-image" src={image} /> : null}
      <span className="estimate-summary-title">{title}</span>
      {typeof startingFromPrice === 'number' ? (
        <div className="estimate-summary-price">
          <span className="estimate-summary-price-label">Starting from</span>
          <MoneyAmount cents={startingFromPrice} />
        </div>
      ) : null}
      <div className="estimate-summary-description">{description}</div>
      <div className="estimate-summary-warning">
        <div className="estimate-summary-warning-left">
          <div className="estimate-summary-warning-left-avatar">⚠️</div>
        </div>
        <div className="estimate-summary-warning-right">
          <span className="estimate-summary-warning-right-label">
            DISCLAIMER
          </span>
          <span className="estimate-summary-warning-right-title">
            This is only informative offer, price can change.
          </span>
        </div>
      </div>
    </div>
  );
}

export default EstimateSummary;
