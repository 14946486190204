/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { useUserState } from '../../store/selectors';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import LeadAnswers, {
  LeadAnswersAnswerProp,
} from '../../components/LeadAnswers';
import './index.scss';
import LeadInfo from '../../components/LeadInfo';
import EstimateSummary from '../../components/EstimateSummary';
import { Helmet } from 'react-helmet';
import APIService from '../../services/api/index.api';
import { RouterPaths } from '../../router/paths.d';
import { InputType } from '../../services/api/types/answers.response.d';
import useWindowSize from '../../hooks/use-windows-size';
import { ClientUTM } from '../../services/api/types/client.response.d';
import LeadUTMInfo from '../LeadUTMInfo';
import Toggler from '../../components/Toggler';
import TextArea, { TextAreaPropsError } from '../../components/Textarea';
import { message } from 'antd';
import { LeadNote } from '../../services/api/types/leads.response';
import { FormResultEntity } from '../../services/api/types/forms.response';

type RightMenuType = 'details' | 'notes';

function SingleLeadPage() {
  const { leadId } = useParams();
  const { token, visitorType, defaultForm, isAgency } =
    useSelector(useUserState);
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const [rightMenuActive, setRightMenuActive] =
    React.useState<RightMenuType>('details');
  const [newNote, setNewNote] = React.useState<string>('');
  const [newNoteErrors, setNewNoteErrors] = React.useState<TextAreaPropsError>(
    [],
  );
  const [leadNotes, setLeadNotes] = React.useState<LeadNote[] | null>([]);

  async function createNewNote() {
    if (newNote.length === 0) {
      setNewNoteErrors(['Note can not be empty']);
      return;
    }
    setNewNoteErrors([]);
    // Create note
    if (!token || !leadId) return;
    const response = await APIService.leadsService.createNote(token, {
      leadId: parseInt(leadId, 10),
      note: newNote,
    });
    if (response === null) {
      // Error
      message.error('Failed to create note');
    } else {
      // Success
      message.success('Note created successfully');
      setNewNote('');
      loadLeadNotes();
    }
  }

  async function loadLeadNotes() {
    if (!token || !leadId) return;
    const response = await APIService.leadsService.listNotes(
      token,
      parseInt(leadId, 10),
    );
    if (response === null) {
      // Error
      message.error('Failed to load notes');
      setLeadNotes([]);
    } else {
      // Success
      setLeadNotes(response.data);
    }
  }

  React.useEffect(() => {
    if (visitorType !== 'user') {
      navigate('/');
    }
  }, []);

  const [data, setData] = React.useState<{
    answers: LeadAnswersAnswerProp[];
    name: string;
    createdAt: Date;
    email: string;
    phoneNumber?: string;
    address?: string;
    mainSelectionTitle?: string;
    mainSelectionDescription?: string;
    startingFromPrice?: number;
    mainSelectionImage?: string;
    additionalNotes?: string;
    businessName?: string;
    calendlyUrl?: string;
    utm: ClientUTM[];
    result?: FormResultEntity;
  } | null>(null);

  const loadLeadAnswers = React.useCallback(async () => {
    const leadIdNumber = leadId && parseInt(leadId, 10);
    if (token && leadIdNumber && !Number.isNaN(leadIdNumber)) {
      // Load data
      const leadResponse = await APIService.leadsService.getUserLead(
        token,
        leadIdNumber,
        isAgency ? (defaultForm as number) : undefined,
      );
      if (leadResponse === null) {
        return navigate(RouterPaths.LEADS);
      }
      const answersResponse =
        await APIService.answersService.getUserLeadAnswers(
          token,
          leadIdNumber,
          isAgency ? (defaultForm as number) : undefined,
        );
      if (answersResponse === false) {
        return navigate(RouterPaths.LEADS);
      } else {
        setData({
          answers: answersResponse.data.answers.map((answer) => ({
            question: answer.input.question,
            answer:
              answer.input.inputType !== InputType.SINGLE_SELECT
                ? answer.textAnswer
                : answer.options?.[0]?.option?.value,
            id: answer.id,
          })),
          result: leadResponse.data.result ?? undefined,
          businessName: leadResponse.data.businessName || undefined,
          calendlyUrl: leadResponse.data.schedule?.calendlyUrl || undefined,
          name: `${leadResponse.data.firstName} ${leadResponse.data.lastName}`,
          createdAt: new Date(leadResponse.data.createdAt),
          email: leadResponse.data.email,
          phoneNumber: leadResponse.data.phoneNumber || undefined,
          address: leadResponse.data.address || undefined,
          additionalNotes: leadResponse.data.additionalNotes || undefined,
          startingFromPrice:
            typeof leadResponse.data.result?.price === 'number'
              ? leadResponse.data.result.price
              : undefined,
          mainSelectionTitle: leadResponse.data.result?.title
            ? leadResponse.data.result?.title
            : undefined,
          mainSelectionDescription: leadResponse.data.result?.description
            ? leadResponse.data.result.description
            : undefined,
          mainSelectionImage: leadResponse.data.result
            ? leadResponse.data.result?.image
              ? APIService.imageService.createImageUrl(
                  leadResponse.data.result.image.id,
                )
              : undefined
            : undefined,
          utm: leadResponse.data.client?.utm || [],
        });
        // Remove lead as new
        APIService.leadsService.removeNewLead(
          token,
          parseInt(leadId, 10),
          defaultForm as any,
        );
      }
    } else {
      navigate(RouterPaths.LEADS);
    }
  }, [token, leadId, defaultForm]);

  const phoneCallback = React.useCallback(() => {
    if (data?.phoneNumber) {
      window.open(`tel:${data.phoneNumber}`, '_self');
    }
  }, [data]);

  const printCallback = React.useCallback(() => {
    // console.log('HI');
  }, []);

  React.useEffect(() => {
    loadLeadAnswers();
    loadLeadNotes();
  }, [leadId]);

  if (data === null) {
    <>
      <Helmet title={`Tapform | Loading Lead...`} />
    </>;
    return (
      <>
        <Helmet title={`Tapform | Loading Lead...`} />
      </>
    );
  }

  const createdAtMoment = moment(data.createdAt);

  return (
    <>
      <Helmet title={`Tapform | ${data.name}`} />
      <div className="single-lead-page-container">
        {windowSize.width && windowSize.width <= 1024 ? (
          <div className="single-lead-page-mobile-actions">
            {data.phoneNumber ? (
              <Button
                variant="phone"
                className="single-lead-page-info-right-actions-phone"
                text="Start a phone call"
                onClick={phoneCallback}
              />
            ) : null}
            {/* <Button
              className="single-lead-page-info-right-actions-print"
              variant="print"
              text="Print"
              onClick={printCallback}
            /> */}
          </div>
        ) : null}
        <div className="single-lead-page-info">
          <div className="single-lead-page-info-left">
            <Button
              variant="back"
              text="Back"
              className="single-lead-page-info-left-back"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h1 className="single-lead-page-info-left-name">{data.name}</h1>
            <span className="single-lead-page-info-left-date">{`${createdAtMoment.format(
              'MMMM D, YYYY',
            )} - ${createdAtMoment.fromNow()}`}</span>
          </div>
          <div className="single-lead-page-info-right">
            {windowSize.width && windowSize.width > 1024 ? (
              <div className="single-lead-page-info-right-actions">
                {data.phoneNumber ? (
                  <Button
                    variant="phone"
                    className="single-lead-page-info-right-actions-phone"
                    text="Start a phone call"
                    onClick={phoneCallback}
                  />
                ) : null}
                {/* <Button
                  className="single-lead-page-info-right-actions-print"
                  variant="print"
                  text="Print"
                  onClick={printCallback}
                /> */}
              </div>
            ) : null}
          </div>
        </div>
        <div className="single-lead-page-holder">
          {windowSize.width && windowSize.width <= 1024 ? (
            data.mainSelectionTitle !== undefined &&
            data.mainSelectionDescription !== undefined ? (
              <EstimateSummary
                title={data.mainSelectionTitle}
                description={data.mainSelectionDescription}
                startingFromPrice={data.startingFromPrice}
                image={data.mainSelectionImage}
              />
            ) : null
          ) : null}
          <LeadAnswers
            answers={data.answers}
            className="single-lead-page-holder-left"
            additionalNotes={data.additionalNotes}
          />
          <div className="single-lead-page-holder-right">
            <Toggler
              className="single-lead-page-holder-right-menu-toggler"
              items={['Details', 'Notes']}
              active={rightMenuActive === 'details' ? 0 : 1}
              onClick={(index) => {
                if (index === 0) {
                  setRightMenuActive('details');
                } else {
                  setRightMenuActive('notes');
                }
              }}
            />
            {rightMenuActive === 'details' ? (
              <>
                <LeadInfo
                  name={data.name}
                  createdAt={data.createdAt}
                  phone={data.phoneNumber}
                  email={data.email}
                  address={data.address}
                  businessName={data.businessName}
                  calendlyUrl={data.calendlyUrl}
                  className="single-lead-page-holder-right-info"
                />
                <LeadUTMInfo
                  utm={data.utm}
                  className="single-lead-page-holder-right-utm"
                />
                {windowSize.width && windowSize.width > 1024 ? (
                  data.mainSelectionTitle !== undefined &&
                  data.mainSelectionDescription !== undefined ? (
                    <EstimateSummary
                      title={data.mainSelectionTitle}
                      description={data.mainSelectionDescription}
                      startingFromPrice={data.startingFromPrice}
                      image={data.mainSelectionImage}
                    />
                  ) : null
                ) : null}
              </>
            ) : (
              <>
                <TextArea
                  className="single-lead-page-holder-right-notes-textarea"
                  placeholder="Type your note here..."
                  resize={'none'}
                  value={newNote}
                  onChange={(value) => {
                    setNewNote(value);
                    if (value.length === 0) {
                      setNewNoteErrors(['Note can not be empty']);
                    } else {
                      setNewNoteErrors([]);
                    }
                  }}
                  errors={newNoteErrors}
                />
                <Button
                  className="single-lead-page-holder-right-notes-button"
                  text="Create New Note"
                  onClick={createNewNote}
                  variant="copy-link"
                />
                <span className="single-lead-page-holder-right-notes-title">
                  Notes
                </span>
                {leadNotes === null ? (
                  <span className="single-lead-page-holder-right-notes-loading">
                    Loading notes...
                  </span>
                ) : (
                  leadNotes.map((note) => (
                    <div
                      key={note.id}
                      className="single-lead-page-holder-right-notes-note"
                    >
                      <div className="single-lead-page-holder-right-notes-note-author">
                        <span className="single-lead-page-holder-right-notes-note-date">
                          {moment(note.createdAt).format('MMMM D, YYYY')}
                        </span>
                        {', '}
                        <span className="single-lead-page-holder-right-notes-note-author-name">
                          {note.author
                            ? `${note.author.firstName as string} ${
                                note.author.lastName as string
                              }`
                            : note.authorAgency
                            ? `${note.authorAgency.firstName as string} ${
                                note.authorAgency.lastName as string
                              }`
                            : 'Unknown'}
                        </span>
                      </div>
                      <span className="single-lead-page-holder-right-notes-note-text">
                        {note.note}
                      </span>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleLeadPage;
